import React, { useContext } from "react"
import { Form, Formik } from "formik"

import MedicineBox from "elements/MedicineBox"
import Search from "../../../../Search/Search"
import FormInput from "../../../../Elements/Form/FormInput"
import AddMoreMedicinesButton from "./AddMoreMedicinesButton"

import { AppContext } from "../../../../../context/AppContext"

const AddMedicinesSection = ({
  showSearch,
  setShowSearch,
  medensureFormulary,
}) => {
  const { state, dispatch } = useContext(AppContext)

  const handleSubmit = (values) => {
    let medicine = values.medicine
    const medicineList = [...state.updatedPrescription.medicines]
    if (!medicine.molecule)
      medicineList.push({ ...medicine, dailyIntake: values.dailyIntake })
    else medicineList.push({ ...medicine, dailyIntake: values.dailyIntake })
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        updatedPrescription: {
          ...state.updatedPrescription,
          medicines: medicineList,
        },
      },
    })
    setShowSearch(false)
  }

  let selectedMedicines = state.updatedPrescription.medicines || []
  selectedMedicines = selectedMedicines.map((medicine) => {
    return medicine.drugName
  })

  const searchComponentProps = {
    placeholder: "Metformin",
    dataSource: {
      type: "json",
      data: medensureFormulary.filter(
        (medicine) => !selectedMedicines.includes(medicine.drugName)
      ),
      lookupKeys: ["molecule", "drugName"],
    },
    message: null,
  }

  switch (true) {
    case !!showSearch:
      return (
        <MedicineBox
          handleDelete={
            state?.updatedPrescription?.medicines?.length === 0
              ? null
              : () => setShowSearch(false)
          }
        >
          <Formik
            initialValues={{ medicine: null, dailyIntake: "" }}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Search
                  searchType="drug"
                  label="Search Medicines"
                  {...searchComponentProps}
                  onSelect={(item) => setFieldValue("medicine", item)}
                  value={values?.medicine?.drugName}
                />
                <FormInput
                  name="dailyIntake"
                  label="Daily Intake"
                  type="number"
                  maxLength="2"
                  hasAddons
                  min="1"
                  max="10"
                  addonRight={
                    <button className="button is-static">per day</button>
                  }
                  isRequired
                />
                <button
                  className="button is-primary is-fullwidth"
                  type="submit"
                  disabled={!values.dailyIntake || !values?.medicine?.drugName}
                >
                  Add Medicine
                </button>
              </Form>
            )}
          </Formik>
        </MedicineBox>
      )
    case !showSearch && state?.updatedPrescription?.medicines?.length > 0:
      return (
        <AddMoreMedicinesButton
          handleClick={() => {
            setShowSearch(true)
          }}
        />
      )
    default:
      return null
  }
}

export default AddMedicinesSection
